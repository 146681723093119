<template>
  <div>
    <Home></Home>
  </div>
</template>

<script>
// @ is an alias to /src
import Home from '@/components/Home.vue'

export default {
  name: 'Anasayfa',
  components: {
    Home,
  }
}
</script>
