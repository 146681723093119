<template>
      <div class="locale-changer dropdown">
        <button class="btn btn-secondary" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!--<img :src="require(`@/assets/images/flag/flag_${$i18n.locale}.png`)"  alt="flag" width="23px">-->{{$t('langsec.'+$i18n.locale)}}
          
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          
            <span v-for="item in this.$i18n.availableLocales" :key="item.id">
              <a href="#" @click.prevent="setLocale(item)" class="dropdown-item" >{{$t('langsec.'+item)}}
                <!--<img :src="require(`@/assets/images/flag/flag_${item}.png`)" alt="flag" width="23px">-->
              </a>
            </span>
        </div>
      </div>
</template>

<script>

export default {
  
  name: 'locale-changer',
  data() {
    return {
      langs: this.$i18n.availableLocales,
    }
  },
  methods: {
    setLocale(locale){
      this.$i18n.locale = locale,
      this.$router.push({
        params: {lang : locale},
        
      })
      window.location.reload();
    },
    langSession(){
      var pathname = window.location.pathname.split("/"),
      lang = pathname[1];
      var diller = this.$i18n.availableLocales;
      if(!diller.includes(lang) && lang!="account" ){
        if(!diller.includes(this.$session.get('lang'))){
          const tarayicidili = navigator.language;
          const pathname = tarayicidili.split("-");
          let dil = pathname[0];
          window.location.href = `/${dil}`;
        }else{
          window.location.href = `/${this.$session.get('lang')}`;
        }
        
      }
      this.$session.start()
      this.$session.set('lang',lang)
    }
  },
  mounted() {
    this.langSession();
  },
}
</script>

<style scoped>
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: 0;
}
.btn.btn-secondary {
    color: #009ef7;
    background-color: transparent;
}
.btn-check:active+.btn.btn-secondary, .btn-check:checked+.btn.btn-secondary, .btn.btn-secondary.active, .btn.btn-secondary.show, .btn.btn-secondary:active:not(.btn-active), .btn.btn-secondary:focus:not(.btn-active), .btn.btn-secondary:hover:not(.btn-active), .show>.btn.btn-secondary {
    color: #8A94A7;
    background-color: transparent !important;
}
</style>