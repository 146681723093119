
<template>
  <div class="is-boxed has-animations">
    <div class="body-wrap">
      <header class="site-header">
        <div class="container">
          <div class="site-header-inner">
            <div class="brand header-brand">
              <span class="fs-5 ms-1 text-muted">Beta</span>
              <span class="m-0">
                <a :href="SITE_URL">
                  <img
                    class="header-logo-image"
                    :src="require('@/assets/images/logo.webp')"
                    alt="Logo"
                    width="230"
                    height="32"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section class="hero">
          <div class="container">
            <div class="hero-inner">
              <div class="hero-copy">
                <h1 class="hero-title mt-0">{{ $t("homepage.title") }}</h1>
                <p class="hero-paragraph">
                  {{ $t("homepage.slogan1") }}
                </p>
                <div class="hero-cta">
                  <a
                    class="button button-primary"
                    :href="`/${this.$i18n.locale}/login`"
                    >{{ $t("homepage.nowearn") }}</a
                  ><a class="button" :href="`/${this.$i18n.locale}/login`">{{
                    $t("homepage.getcredit")
                  }}</a>
                </div>
                <div class="mt-150">
                  <h2 class="hero-title2">{{ $t("homepage.title2") }}</h2>
                  <h2 class="hero-paragraph">
                    {{ $t("homepage.slogan2") }}
                  </h2>
                </div>
              </div>

              <div class="hero-figure anime-element">
                <svg
                  class="placeholder"
                  width="528"
                  height="396"
                  viewBox="0 0 528 396"
                >
                  <rect width="528" height="396" style="fill: transparent" />
                </svg>
                <div
                  class="hero-figure-box hero-figure-box-01"
                  data-rotation="45deg"
                ></div>
                <div
                  class="hero-figure-box hero-figure-box-02"
                  data-rotation="-45deg"
                ></div>
                <div
                  class="hero-figure-box hero-figure-box-03"
                  data-rotation="0deg"
                ></div>
                <div
                  class="hero-figure-box hero-figure-box-04"
                  data-rotation="-135deg"
                ></div>
                <div class="hero-figure-box hero-figure-box-05"></div>
                <div class="hero-figure-box hero-figure-box-06"></div>
                <div class="hero-figure-box hero-figure-box-07"></div>
                <div
                  class="hero-figure-box hero-figure-box-08"
                  data-rotation="-22deg"
                ></div>
                <div
                  class="hero-figure-box hero-figure-box-09"
                  data-rotation="-52deg"
                ></div>
                <div
                  class="hero-figure-box hero-figure-box-10"
                  data-rotation="-50deg"
                ></div>
              </div>
            </div>
          </div>
          <div class="separator mt-20 my-10"></div>
          <div class="container">
          <div class="hero-inner mt-20">
              <div class="hero-copy mt-20">
                <div class="card mb-10 adsxzq">
                  <div class="card-header m-auto">
                    <!--begin::Svg Icon | path: assets/media/icons/duotune/ecommerce/ecm011.svg-->
                    <span class="svg-icon svg-icon-primary svg-icon-4hx mt-2"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M21.6 11.2L19.3 8.89998V5.59993C19.3 4.99993 18.9 4.59993 18.3 4.59993H14.9L12.6 2.3C12.2 1.9 11.6 1.9 11.2 2.3L8.9 4.59993H5.6C5 4.59993 4.6 4.99993 4.6 5.59993V8.89998L2.3 11.2C1.9 11.6 1.9 12.1999 2.3 12.5999L4.6 14.9V18.2C4.6 18.8 5 19.2 5.6 19.2H8.9L11.2 21.5C11.6 21.9 12.2 21.9 12.6 21.5L14.9 19.2H18.2C18.8 19.2 19.2 18.8 19.2 18.2V14.9L21.5 12.5999C22 12.1999 22 11.6 21.6 11.2Z"
                          fill="black"
                        />
                        <path
                          d="M11.3 9.40002C11.3 10.2 11.1 10.9 10.7 11.3C10.3 11.7 9.8 11.9 9.2 11.9C8.8 11.9 8.40001 11.8 8.10001 11.6C7.80001 11.4 7.50001 11.2 7.40001 10.8C7.20001 10.4 7.10001 10 7.10001 9.40002C7.10001 8.80002 7.20001 8.4 7.30001 8C7.40001 7.6 7.7 7.29998 8 7.09998C8.3 6.89998 8.7 6.80005 9.2 6.80005C9.5 6.80005 9.80001 6.9 10.1 7C10.4 7.1 10.6 7.3 10.8 7.5C11 7.7 11.1 8.00005 11.2 8.30005C11.3 8.60005 11.3 9.00002 11.3 9.40002ZM10.1 9.40002C10.1 8.80002 10 8.39998 9.90001 8.09998C9.80001 7.79998 9.6 7.70007 9.2 7.70007C9 7.70007 8.8 7.80002 8.7 7.90002C8.6 8.00002 8.50001 8.2 8.40001 8.5C8.40001 8.7 8.30001 9.10002 8.30001 9.40002C8.30001 9.80002 8.30001 10.1 8.40001 10.4C8.40001 10.6 8.5 10.8 8.7 11C8.8 11.1 9 11.2001 9.2 11.2001C9.5 11.2001 9.70001 11.1 9.90001 10.8C10 10.4 10.1 10 10.1 9.40002ZM14.9 7.80005L9.40001 16.7001C9.30001 16.9001 9.10001 17.1 8.90001 17.1C8.80001 17.1 8.70001 17.1 8.60001 17C8.50001 16.9 8.40001 16.8001 8.40001 16.7001C8.40001 16.6001 8.4 16.5 8.5 16.4L14 7.5C14.1 7.3 14.2 7.19998 14.3 7.09998C14.4 6.99998 14.5 7 14.6 7C14.7 7 14.8 6.99998 14.9 7.09998C15 7.19998 15 7.30002 15 7.40002C15.2 7.30002 15.1 7.50005 14.9 7.80005ZM16.6 14.2001C16.6 15.0001 16.4 15.7 16 16.1C15.6 16.5 15.1 16.7001 14.5 16.7001C14.1 16.7001 13.7 16.6 13.4 16.4C13.1 16.2 12.8 16 12.7 15.6C12.5 15.2 12.4 14.8001 12.4 14.2001C12.4 13.3001 12.6 12.7 12.9 12.3C13.2 11.9 13.7 11.7001 14.5 11.7001C14.8 11.7001 15.1 11.8 15.4 11.9C15.7 12 15.9 12.2 16.1 12.4C16.3 12.6 16.4 12.9001 16.5 13.2001C16.6 13.4001 16.6 13.8001 16.6 14.2001ZM15.4 14.1C15.4 13.5 15.3 13.1 15.2 12.9C15.1 12.6 14.9 12.5 14.5 12.5C14.3 12.5 14.1 12.6001 14 12.7001C13.9 12.8001 13.8 13.0001 13.7 13.2001C13.6 13.4001 13.6 13.8 13.6 14.1C13.6 14.7 13.7 15.1 13.8 15.4C13.9 15.7 14.1 15.8 14.5 15.8C14.8 15.8 15 15.7 15.2 15.4C15.3 15.2 15.4 14.7 15.4 14.1Z"
                          fill="black"
                        /></svg
                    ></span>
                    <!--end::Svg Icon-->
                    <h2 class="card-title text-light">
                      {{ $t("homepage.lend") }}
                    </h2>
                  </div>
                  <div class="card-body">
                    <h3 class="fs-6 p-0 m-0 fw-lighter text-light text-center">
                      {{ $t("homepage.lendtitle") }}
                    </h3>
                  </div>
                </div>
                <div class="card mb-10 adsxzq">
                  <div class="card-header m-auto">
                    <!--begin::Svg Icon | path: assets/media/icons/duotune/communication/com014.svg-->
                    <span class="svg-icon svg-icon-primary svg-icon-3hx mt-4"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                          fill="black"
                        />
                        <rect
                          opacity="0.3"
                          x="14"
                          y="4"
                          width="4"
                          height="4"
                          rx="2"
                          fill="black"
                        />
                        <path
                          d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                          fill="black"
                        />
                        <rect
                          opacity="0.3"
                          x="6"
                          y="5"
                          width="6"
                          height="6"
                          rx="3"
                          fill="black"
                        /></svg
                    ></span>
                    <!--end::Svg Icon-->
                    <h2 class="card-title text-light m-auto">
                      {{ $t("homepage.borrow") }}
                    </h2>
                  </div>
                  <div class="card-body ">
                    <h3 class="fs-6 p-0 m-0 fw-lighter text-light m-auto">
                      {{ $t("homepage.borrowtitle") }}
                    </h3>
                  </div>
                </div>
                <div class="card mb-10 adsxzq">
                  <div class="card-header m-auto">
                    <!--begin::Svg Icon | path: assets/media/icons/duotune/finance/fin010.svg-->
                    <span class="svg-icon svg-icon-primary svg-icon-3hx mt-4"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z"
                          fill="black"
                        />
                        <path
                          d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z"
                          fill="black"
                        /></svg
                    ></span>
                    <!--end::Svg Icon-->
                    <h2 class="card-title text-light">
                      {{ $t("homepage.earn") }}
                    </h2>
                  </div>
                  <div class="card-body">
                    <h3 class="fs-6 p-0 m-0 fw-lighter text-light m-auto text-center">
                      {{ $t("homepage.earntitle") }}
                    </h3>
                  </div>
                </div>
                <div class="card adsxzq">
                  <div class="card-header m-auto">
                    <!--begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr031.svg-->
                    <span class="svg-icon svg-icon-primary svg-icon-3hx mt-4"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                      >
                        <path
                          d="M21 13V13.5C21 16 19 18 16.5 18H5.6V16H16.5C17.9 16 19 14.9 19 13.5V13C19 12.4 19.4 12 20 12C20.6 12 21 12.4 21 13ZM18.4 6H7.5C5 6 3 8 3 10.5V11C3 11.6 3.4 12 4 12C4.6 12 5 11.6 5 11V10.5C5 9.1 6.1 8 7.5 8H18.4V6Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M21.7 6.29999C22.1 6.69999 22.1 7.30001 21.7 7.70001L18.4 11V3L21.7 6.29999ZM2.3 16.3C1.9 16.7 1.9 17.3 2.3 17.7L5.6 21V13L2.3 16.3Z"
                          fill="black"
                        /></svg
                    ></span>
                    <!--end::Svg Icon-->
                    <h2 class="card-title text-light ms-1">
                      {{ $t("homepage.swap") }}
                    </h2>
                  </div>
                  <div class="card-body">
                    <h3 class="fs-6 p-0 m-0 fw-lighter text-light m-auto text-center">
                      {{ $t("homepage.swaptitle") }}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="w-100 dashboard-image">
                <img
                  :src="require('@/assets/images/anasayfaimage.webp')"
                  alt="dashboard"
                  width="838"
                  height="564"
                />
              </div>
            </div>
          </div>
            
          <div class="separator mt-20 my-10"></div>
          <div class="container mt-20">
            <div class="hero-copy  w-100">
              <div class="card mb-10 bg-transparent">
                <div class="card-header text-center m-auto">
                  <h2 class="card-title text-light">
                    {{ $t("homepage.team") }}
                  </h2>
                </div>
                <div class="card-body team text-center m-auto">
                  <img
                    :src="require('')"
                    width="150"
                    height="150"
                    alt="Altcoincase"
                  />
                  <h2 class="fs-6 p-1 m-0 fw-lighter lh-sm">Altcoincase</h2>
                  <h2 class="fs-6 p-1 m-0 fw-lighter text-muted lh-sm">Founder</h2>
                  <a href="">
                    <span class="screen-reader-text">Linkedin</span>
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      class="m-auto"
                    >
                      <path
                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                        fill="#0270D7"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer class="site-footer">
        <div class="container">
          <div class="site-footer-inner">
            <div class="brand footer-brand"></div>
            <ul class="footer-links list-reset">
              <li>
                <a v-if="this.$i18n.locale=='tr'" href="/Whitepaper_tr.pdf" target="_blank">Whitepaper</a>
                <a v-if="this.$i18n.locale!='tr'" href="/Whitepaper_en.pdf" target="_blank">Whitepaper</a>
              </li>
              <li class="ms-4">
                <a :href="`/${this.$i18n.locale}/about`">{{
                  $t("homepage.aboutus")
                }}</a>
              </li>
              <li class="ms-4">
                <a href="#">{{ $t("homepage.faqs") }}</a>
              </li>
              <li class="ms-4">
                <a :href="`/${this.$i18n.locale}/support`">{{
                  $t("homepage.support")
                }}</a>
              </li>
              <li class="ms-4">
                <language-switcher class="someClass"></language-switcher>
              </li>
            </ul>
            <ul class="footer-social-links list-reset">
              <li>
                <a
                  href="https://twitter.com/altcoincase?t=50pwg2CIJPgsbYbPYEah0A&s=09"
                >
                  <span class="screen-reader-text">Twitter</span>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                      fill="#0270D7"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/altcoincase/">
                  <span class="screen-reader-text">Linkedin</span>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                      fill="#0270D7"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            <div class="footer-copyright text-light">
              <a href="#">
                <img
                  class="header-logo-image"
                  :src="require('@/assets/images/altcoincase-icon.webp')"
                  alt="Logo"
                  width="35"
                  height="34"
                />
              </a>
              <span class="text-light ms-2 mt-2"
                >&copy; 2021 Altcoincase,
                {{ $t("homepage.allrightsreserved") }}</span
              >
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
//import DataService from "../services/DataService";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
export default {
  components: { LanguageSwitcher },
  name: "Anasayfa",
  
  metaInfo: function() {
    return {
      title: this.$t("metatag.titledefault"),
      titleTemplate: "%s | "+this.$t("metatag.titleDetailHome"),
      meta: [
      { name: 'description', content:  this.$t("metatag.description") },
      { name: 'keywords', content:  this.$t("metatag.keywords") }
      ]
    }
  },
  data() {
    return {
      SITE_URL: process.env.VUE_APP_SITE_URL,
    };
  },
  methods: {
    userSessionControl() {
      const d = new Date();
        let time = d.getTime().toString().substring(0, 10);
        if(this.$session.get("sessionTimeout")<time || !this.$session.get("sessionTimeout") || !this.$session.get("logtoken")){
          this.$session.destroy()
        }
      /*if (this.$session.get("logtoken")) {
        DataService.userSessionControl({
          token: this.$session.get("logtoken"),
        }).then((response) => {
          if (response.data.error && response.data.status != "ok") {
            this.$session.destroy();
          }
        });
      }*/
    },
  },
  mounted() {
    this.userSessionControl();
  },
};
</script>

<style lang="" scoped src="../assets/anasayfa/css/style.css"></style>



<style scoped>
.card-header {
  border: none !important;
}
.card .card-body {
    padding: 1rem 2.25rem;
}
.card {
  background-color: #242830;
 /* border-radius: 50px 0;*/
}
.adsxzq:hover{
  box-shadow: 0 9px 20px 0 rgb(62 123 151 / 30%)
}
h2, .h2{
    line-height: 22px;
}
.team img {
  border-radius: 50% !important;
  margin: auto;
}
.separator {
    border-bottom: 1px solid #242830 !important;
}
.dashboard-image img{
  max-width: 838px; margin-top:65px
}

@media (max-width: 640px){
.dashboard-image img{
 display: none;
}
}
</style>